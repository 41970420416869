<template>
    <div>
        <b-form>
            <b-row >
                <b-col md="1" xl="1" class="mb-1"> 
                    <b-form-group label="Especie" label-for="basicInput">                        
                        <b-form-select @change="productTypeSelected" :options="productTypes"
                        value-field="productType" text-field="productType" placeholder="Seleccione"/>
                    </b-form-group>                    
                </b-col>
                <b-col md="1" xl="1" class="mb-1">
                    <b-form-group label="Categoría" label-for="basicInput">                        
                        <b-form-select @change="categorySelected" v-model="category" :options="categoryList" value-field="category" text-field="category" placeholder="Seleccione"/>
                    </b-form-group>
                </b-col>
                <b-col md="1" xl="1" class="mb-1"> 
                    <b-form-group label="Departamento" label-for="basicInput">                        
                        <b-form-select @change="onDepartamentoSelect" :options="departamentosList" 
                        value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                    </b-form-group>                   
                </b-col>
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Precio mínimo Kg" label-for="basicInput">                        
                        <b-form-input v-model = "priceMinimum"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Precio máximo Kg" label-for="basicInput">                        
                        <b-form-input v-model = "priceMaximum"></b-form-input>
                    </b-form-group>                    
                </b-col>
                <b-col md="1" xl="1" class="mt-2">                          
                    <b-button  variant="primary" @click="searchCattleOfferings" >
                        <feather-icon icon="SearchIcon" class="mr-50"/>Filtrar
                    </b-button>
                </b-col>
                <!-- <b-col md="2" xl="2" class="mt-2 text-right" offset-lg="2">
                    <b-button  variant="warning" @click="addNewCattleOffering" block>
                        <feather-icon icon="PlusIcon" class="mr-50"/>Adicionar oferta
                    </b-button>                        
                </b-col>                                       -->
            </b-row>
        </b-form>

        <b-row v-if="cattleOfferings" class="match-height">
            <b-col md="6" lg="4" v-for="item in cattleOfferings" :key="item.cattleOfferingID">
                <b-card v-if="item.offerStatus == 'En Venta'">
                    <b-carousel id="carousel-example-generic" controls indicators :interval="0" img-height="700px">          
                        <b-carousel-slide v-if="item.projectVideoUrl1">
                            <template #img>
                            <iframe :src="item.projectVideoUrl1" class="sosty-project-details-video" frameborder="0" style="max-height:300px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectVideoUrl2">
                            <template #img>
                            <iframe :src="item.projectVideoUrl2" class="sosty-project-details-video" frameborder="0" style="max-height:300px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl1">
                            <template #img>
                            <b-img :src="item.projectImageUrl1" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl2">
                            <template #img>
                            <b-img :src="item.projectImageUrl2" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                <b-card-title class="mb-0">
                    <b-row class="mb-0 mt-1">
                        <b-col>
                            <h3>Lote #{{item.cattleOfferCode}}</h3>
                        </b-col>
                        <b-col> 
                            <b-row style="align-items:center;">  
                                <b-col style="text-align:right;">
                                    <h6 style="font-size:10px; color:#008D36;">{{item.locationState}} - {{item.locationCity}}
                                        <b-avatar  size="18" variant="light-primary">
                                            <feather-icon size="14" icon="MapPinIcon"/>
                                        </b-avatar>  
                                    </h6>                              
                                </b-col>
                            </b-row>

                            <b-row style="align-items:center;">  
                                <b-col style="text-align:right;">
                                    <h6 style="font-size:10px; color:#008D36;">{{item.offerStatus}}    
                                        <b-avatar  size="18" variant="light-primary">
                                            <feather-icon size="14" icon="FlagIcon"/>
                                        </b-avatar>                                      
                                    </h6>                              
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-title>                                       
                <b-card-body>
                    <b-row class="mt-0">
                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0">
                             <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="TagIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{item.amountOfAnimals}}</h4>
                                    <b-card-text class="font-small-3 mb-0">Animales</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        
                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="BookmarkIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{(item.productType)}}</h4>
                                    <b-card-text class="font-small-3 mb-0">Especie</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="FolderIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h5 class="font-weight-bolder mb-0">{{(item.category)}} </h5>
                                    <b-card-text class="font-small-3 mb-0">Categoria</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="SlackIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">{{(item.breed)}} </h4>
                                    <b-card-text class="font-small-3 mb-0">Raza</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="DollarSignIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">${{parseFloat(item.priceKg).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} <span style="font-size:12px">COP</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Precio Kilogramo</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        
                        <b-col md="6" lg="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="ClipboardIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">{{parseFloat(item.averageWeight).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">Kg</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Peso Promedio Animal</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                    </b-row>

                    <b-row class="mt-2">
                        <b-col md="12" lg="12">
                            <b-alert variant="primary" show>
                                <div class="alert-body" style="padding:0px 14px">
                                    <b-row>
                                        <span style="font-size:16px; padding-left: 7px;"><strong>{{item.newDescription}}</strong></span>
                                    </b-row>
                                </div>                    
                            </b-alert>
                        </b-col> 
                    </b-row>
                </b-card-body>

                    <b-row class="mt-0 mb-1">
                        <b-col md="4" lg="4">
                            <b-button @click="openWhatsapp(item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" target="_blank" block>
                                <feather-icon icon="MessageCircleIcon" class="mr-50"/>
                                <span class="align-middle">Me Interesa</span>
                            </b-button>     
                        </b-col>   
                       
                        <b-col md="4" lg="4">
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="info" @click="goToCattleOfferingDetails(item)" block>
                                <feather-icon icon="EyeIcon" class="mr-50"/>
                                <span class="align-middle">Ver Lote</span>
                            </b-button>
                        </b-col>

                        <b-col md="4" lg="4">
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="warning" @click="openModal(item)" block>                                    
                                <feather-icon icon="CornerUpRightIcon" class="mr-50"/>                                      
                                Compartir
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
                
                <!-- Only CEL -->
                <div class="d-md-none d-lg-none d-xl-none" v-if="item.offerStatus == 'Vendido'"
                            style="width:70%;
                                    border-radius: 3px;
                                    position:absolute;
                                    left: 0px;
                                    z-index:1;
                                    "
                                    
                                        >                           
                                <h1 style="color:white; 
                                        font-size: 40px;                                        
                                        border-radius: 3px;                                                   
                                        text-align:center;                                        
                                        background-color: #FF9F43;
                                        ">VENDIDO
                                </h1>
                            </div>
                <!-- No cel -->
                <b-col class="d-none d-md-block" v-if="item.offerStatus == 'Vendido'"
                            style="width:50%;
                                    border-radius: 3px;
                                    position:absolute;
                                    left: 0px;
                                    z-index:1;
                                    "
                                        >                           
                                <h1 style="color:white; 
                                        font-size: 40px;                                        
                                        border-radius: 3px;                                                   
                                        text-align:center;                                        
                                        background-color: #FF9F43;
                                        ">VENDIDO
                                </h1>
                </b-col>

                <b-card v-if="item.offerStatus == 'Vendido'" 
                    style="opacity:0.3"
                    :img-src="item.projectImageUrl1"
                    img-alt="Card image cap"
                    img-height="300px"                                    
                    img-top 
                    object-fit= cover                                   
                    >
                    
                    <b-carousel id="carousel-example-generic" controls indicators :interval="0" img-height="700px">          
                        <b-carousel-slide v-if="item.projectVideoUrl1">
                            <template #img>
                            <iframe :src="item.projectVideoUrl1" class="sosty-project-details-video" frameborder="0" style="max-height:300px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectVideoUrl2">
                            <template #img>
                            <iframe :src="item.projectVideoUrl2" class="sosty-project-details-video" frameborder="0" style="max-height:300px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl1">
                            <template #img>
                            <b-img :src="item.projectImageUrl1" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl2">
                            <template #img>
                            <b-img :src="item.projectImageUrl2" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                <b-card-title class="mb-0">
                    <b-row class="mb-0 mt-1">
                        <b-col>
                            <h3>Lote #{{item.cattleOfferCode}}</h3>
                        </b-col>
                        <b-col> 
                            <b-row style="align-items:center;">  
                                <b-col style="text-align:right;">
                                    <h6 style="font-size:10px; color:#008D36;">{{item.locationState}} - {{item.locationCity}}
                                        <b-avatar  size="18" variant="light-primary">
                                            <feather-icon size="14" icon="MapPinIcon"/>
                                        </b-avatar>  
                                    </h6>                              
                                </b-col>
                            </b-row>

                            <b-row style="align-items:center;">  
                                <b-col style="text-align:right;">
                                    <h6 style="font-size:10px; color:#008D36;">{{item.offerStatus}}    
                                        <b-avatar  size="18" variant="light-primary">
                                            <feather-icon size="14" icon="FlagIcon"/>
                                        </b-avatar>                                      
                                    </h6>                              
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-title>                                       
                <b-card-body>
                    <b-row class="mt-0">
                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0">
                             <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="TagIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{item.amountOfAnimals}}</h4>
                                    <b-card-text class="font-small-3 mb-0">Animales</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        
                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="BookmarkIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{(item.productType)}}</h4>
                                    <b-card-text class="font-small-3 mb-0">Especie</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="FolderIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h5 class="font-weight-bolder mb-0">{{(item.category)}} </h5>
                                    <b-card-text class="font-small-3 mb-0">Categoria</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="SlackIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">{{(item.breed)}} </h4>
                                    <b-card-text class="font-small-3 mb-0">Raza</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="DollarSignIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">${{parseFloat(item.priceKg).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} <span style="font-size:12px">COP</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Precio Kilogramo</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        
                        <b-col md="6" lg="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="ClipboardIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">{{parseFloat(item.averageWeight).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">Kg</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Peso Promedio Animal</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="DollarSignIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">${{parseFloat(item.totalPrice).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">COP</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Precio Lote</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>

                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="ClipboardIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">                                    
                                    <h4 class="font-weight-bolder mb-0">{{parseFloat(item.totalWeightKg).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">Kg</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Peso Lote</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col md="12" lg="12">
                            <b-alert variant="primary" show>
                                <div class="alert-body" style="padding:0px 14px">
                                    <b-row>
                                        <span style="font-size:16px; padding-left: 7px;"><strong>{{item.newDescription}}</strong></span>
                                    </b-row>
                                </div>                    
                            </b-alert>
                        </b-col> 
                    </b-row>

                </b-card-body>
                    <b-row class="mt-0 mb-1">
                        <b-col md="4" lg="4">
                            <b-button @click="openWhatsapp(item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" target="_blank" block disabled="">
                                <feather-icon icon="MessageCircleIcon" class="mr-50"/>
                                <span class="align-middle">Me Interesa</span>
                            </b-button>     
                        </b-col>   
                       
                        <b-col md="4" lg="4">
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="info" @click="goToCattleOfferingDetails(item)" block disabled="">
                                <feather-icon icon="EyeIcon" class="mr-50"/>
                                <span class="align-middle">Ver Lote</span>
                            </b-button>
                        </b-col>

                        <b-col md="4" lg="4">
                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="warning" v-b-modal.modal-secondary block disabled>                                    
                                <feather-icon icon="CornerUpRightIcon" class="mr-50"/>                                      
                                Compartir
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>        
        </b-row> 

        <b-row v-if="cattleOfferings.length <= 0" >
            <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Proyectos para mostrar</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal ref="my-modal" hide-footer centered title="Compartir">
          <b-card-text> 
             <b-row>
                <b-col md="12" xl="12" class="mb-1">
                    <b-form-group>                    
                      <textarea class="form-control" style="min-width: 100%" v-model="cattleOfferUrl" ref="text" readonly/>                      
                    </b-form-group>
                </b-col>                                  
            </b-row>            
            <b-button class="mt-1" @click="copyLink" variant="primary" block >                
                <span class="align-middle">Copiar Link</span>
            </b-button> 
          </b-card-text>
        </b-modal>

    </div>
</template>

<script>
import {
  BAlert, BCard, BCardText, BForm, BFormText, BInputGroupAppend, BOverlay, BFormInput, BInputGroupText, BFormTextArea, 
  BInputGroupPrepend, BInputGroup, BFormGroup, BFormSelect, BButton, BRow, BCol, BImg, BListGroup, BListGroupItem, BCardBody, 
  BCardTitle, BCardSubTitle, BLink, BProgress, BMedia, BAvatar, BMediaAside, BMediaBody,BTooltip,BProgressBar, BCarousel, BCarouselSlide,
  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import axios from '@axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import departamentos from '../../assets/departamentos-municipios.json'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { double, numeric } from 'vee-validate/dist/rules'
import RisksModalComponent from '@core/components/RisksModalComponent'
import categories from '../../assets/category-types.json'
import breeds from '../../assets/breeds-types.json'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BAlert,
    BCard,    
    BCardText,
    BFormGroup,
    BForm,
    BFormSelect,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCarousel, 
    BCarouselSlide,
    BOverlay,
    BRow,
    BCol,
    BFormText,
    BFormTextArea,
    BFormInput,
    BLink,
    BListGroup,
    BListGroupItem,    
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    BProgress,
    BProgressBar,
    StatisticCardHorizontal,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardCode,
    BTooltip, 
    RisksModalComponent,       
    },
    data(){
        return{                        
            cattleOfferings:[{}],            
            isLoggedIn: false,
            userType: false,
            cattleOfferCode: null,
            countChararters: null,
            cattleOfferUrl: "",
            productType:'',
            category: '',
            locationState: '',       
            priceMinimum: '',
            priceMaximum: '',
            departamentosList: departamentos,
            departamentoSelected:'',
            categoryList: categories,
            isVisible: 1,
        
        productTypes: [
            '',
            'Bufalo',
            'Vacuno',
        ]
        }
    },
    created(){
        var userData = getUserData();
            if(userData){
            this.isLoggedIn = true;
            this.userType = userData.userType;
            }
        this.searchCattleOfferings();              
    },
    watch:{
        minBar: function(val) { this.minBar = val; },
    },
    methods:{
        searchCattleOfferings(){
            axios.get('/CattleOffering/GetPublicTopCatlleOfferings',{params: {productType: this.productType, category: this.category, 
                state: this.locationState, priceMinimum: this.priceMinimum, priceMaximum: this.priceMaximum, isVisible: this.isVisible}})
            .then(response => {
                this.cattleOfferings = response.data;
                if(this.cattleOfferings.length > 0){
                    this.cattleOfferings.forEach(function(item, index){
                        item.averageWeight = (item.totalWeightKg)/ (item.amountOfAnimals)                        

                        var countCharacters = item.offerDescription.length                        
                        if(countCharacters > 100){
                            var newString = item.offerDescription
                            item.newDescription = (newString).substring(0, 97) + "..."
                        }else{
                            item.newDescription = item.offerDescription
                        }
                        
                        if(item.averageWeight == null || item.averageWeight == undefined){
                            item.averageWeight = 0
                        }
                        if(item.averageWeightKg == null || item.averageWeightKg == undefined){
                            item.averageWeightKg = 0
                        }
                        if(item.priceKg == null){
                            item.priceKg = 0
                        }
                        if(item.breed == null){
                            item.breed = '--'
                        }
                        if(item.category == null){
                            item.category = '--'
                        }
                        if(item.totalWeightKg == null || item.totalWeightKg == undefined){
                            item.totalWeightKg = 0
                        }

                    });
                }
            })
        },
        goToCattleOfferingDetails(event) {            
            this.cattleOfferCode = event.cattleOfferCode;
            let route = this.$router.resolve({path: 'cattle-offering-details?cattleOfferCode=' + this.cattleOfferCode});
            window.open(route.href, '_blank');
        },
        copyLink() {
            this.$refs.text.select();
            document.execCommand('copy');
        },
        openWhatsapp(event) {                 
            var code = event.cattleOfferCode;                   
            var url = "https://wa.me/573107993625?text=Hola,+quiero+más+información+del+lote" + " " + code
            window.open(url, '_blank', 'noreferrer');
        },
        openModal(item) {
            this.cattleOfferUrl = window.location.origin + "/cattle-offering-details?cattleOfferCode=" + item.cattleOfferCode;
            this.$refs['my-modal'].show()
        },
        onDepartamentoSelect(event){
            this.locationState = event;        
            var departamento = this.departamentosList.find(x => x.departamento === event);
            this.municipiosList = departamento.ciudades;
        },
        productTypeSelected(event){
            this.productType = event;

        }
    },
    directives: {
        Ripple,
    },     
}
</script>